// Vue
import Vue from 'vue'
// Constants
import { URL_APP_MENU } from '@/constants'
// Components
import SectionInformationBox from '@/components/ui/SectionInformationBox'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapMutations } from 'vuex'
// Utils
import { getPathImage } from '@/utils'
// Confetti
import VueConfetti from 'vue-confetti'

Vue.use(VueConfetti)

export default {
  name: 'OnboardingFinish',
  components: { SectionInformationBox, VuetifyToolBar },
  mixins: [intercomMixin, uiMixin],
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  mounted() {
    this.$confetti.start({
      defaultDropRate: 5,
      defaultSize: 7,
      defaultType: 'rect',
      particlesPerFrame: 0.25,
      windSpeedMax: 0
    })
  },
  destroyed() {
    this.$confetti.stop()
    // Mostramos la alerta del QR
    this.setShowQrAlert(true)
  },
  methods: {
    ...mapMutations('app', ['setShowQrAlert']),
    /**
     * Usuario pulsa en el botón de acción
     */
    handleClickButton() {
      // Modificamos variable de Intercom
      this.intercomUpdate({
        bakarta_onboarding_finish: true
      })
      // Abrimos nueva pestaña con enlace al menú
      window.open(`${URL_APP_MENU}${this.placeData.id}`, '_blank')
      // Redirigimos a la sección de planes
      this.routerPushTo({
        name: 'plansGuide'
      })
    },
    /**
     * Get path image
     */
    getImage(path, brand = false) {
      return getPathImage(path, brand)
    }
  }
}
